import { Add, Link } from '@mui/icons-material';
import { Chip, Stack } from '@mui/material';
import { useTour } from '@reactour/tour';
import React from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { createExamSteps } from '../../containers/tour/steps';
import { getTenantCode } from '../../store/actions';
import { useStyle } from '../../styles/style';
import DashboardTile from './DashboardTile';
const DashBoard = () => {
    const classes = useStyle();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { setCurrentStep, setSteps } = useTour();
    const { dashboardReport, loader } = useSelector((state) => state.exam);
    const { code } = useSelector((state) => state.auth);
    return loader.dashboardReport ? (React.createElement("div", { className: classes.dashboardReportSkeleton },
        React.createElement(Skeleton, { height: 320, width: 625 }),
        React.createElement(Skeleton, { height: 320, width: 625 }),
        React.createElement(Skeleton, { height: 320, width: 625 }))) : (React.createElement("div", null,
        React.createElement(Stack, { spacing: 2, sx: { padding: '1rem' }, direction: "row" },
            React.createElement(Chip, { label: "Create Exam", clickable: true, avatar: React.createElement(Add, null), sx: { height: '2.5rem', width: '8rem' }, onClick: () => {
                    navigate('/exams/add');
                    setSteps === null || setSteps === void 0 ? void 0 : setSteps(createExamSteps);
                    setCurrentStep(0);
                }, id: "createExam" }),
            React.createElement(Chip, { label: "Copy Student SignUp Link", clickable: true, avatar: React.createElement(Link, null), sx: { height: '2.5rem', width: '13rem' }, onClick: () => {
                    dispatch(getTenantCode());
                }, id: "linkCopy" })),
        React.createElement("div", { className: classes.dashboardReportSkeleton }, Object.keys(dashboardReport).map((value) => {
            const report = dashboardReport[value];
            return React.createElement(DashboardTile, { report: report, value: value, key: value });
        }))));
};
export default DashBoard;
