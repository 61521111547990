import { useTheme } from '@material-ui/core';
import { Close } from '@mui/icons-material';
import { Box, Button, Checkbox, Chip, FormControl, IconButton, InputLabel, ListItemText, MenuItem, Modal, OutlinedInput, Select, TextField, } from '@mui/material';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAllExams, getStudentTenantMapping, postStudentExamMapping, } from '../../store/actions';
import { useStyle } from '../../styles/style';
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};
function getStyles(examID, examIDs, theme) {
    return {
        fontWeight: examIDs.indexOf(examID) === -1
            ? theme.typography.fontWeightRegular
            : theme.typography.fontWeightMedium,
    };
}
const StudentExamMappingModal = ({ isModalOpen, handleModalClose }) => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const classes = useStyle();
    const [name, setName] = React.useState();
    const [examIDs, setExamIDs] = React.useState([]);
    const [studentIDs, setStudentIDs] = React.useState([]);
    const exams = useSelector((state) => state.exam.exams);
    const studentTenantMapping = useSelector((state) => state.exam.studentTenantMapping);
    const examIdMap = Object.fromEntries(exams
        .filter((exam) => exam.publishStatus)
        .map((e) => [e.examID, e.examName]));
    const studentIdMap = Object.fromEntries(studentTenantMapping.map((student) => [
        student.studentId,
        `${student.firstName} ${student.middleName} ${student.lastName}    ${student.emailId}`,
    ]));
    const handleChange = (event) => {
        const { target: { value }, } = event;
        setExamIDs(
        // On autofill we get a stringified value.
        typeof value === 'string' ? value.split(',') : value);
    };
    const handleStudentChange = (event) => {
        const { target: { value }, } = event;
        setStudentIDs(
        // On autofill we get a stringified value.
        typeof value === 'string' ? value.split(',') : value);
    };
    useEffect(() => {
        dispatch(getAllExams());
        dispatch(getStudentTenantMapping());
    }, []);
    return (isModalOpen && (React.createElement(Modal, { open: isModalOpen, onClose: handleModalClose, disableScrollLock: true },
        React.createElement(Box, { className: classes.studentExamMappingModal },
            React.createElement("div", { className: classes.closeIconBody },
                React.createElement("div", { className: classes.loginHeader }, "Student Exam Mapping"),
                React.createElement(IconButton, { onClick: handleModalClose, size: "small" },
                    React.createElement(Close, null))),
            React.createElement("div", { className: classes.studentExamMappingBody },
                React.createElement(TextField, { label: "Map Name", onChange: (data) => setName(data.target.value), value: name, size: "small" }),
                React.createElement(FormControl, { sx: { m: '1', width: '-webkit-fill-available' }, size: "small" },
                    React.createElement(InputLabel, { id: "demo-multiple-checkbox-label" }, "Exams"),
                    React.createElement(Select, { labelId: "demo-multiple-checkbox-label", id: "demo-multiple-checkbox", multiple: true, value: examIDs, onChange: handleChange, input: React.createElement(OutlinedInput, { id: "select-multiple-chip", label: "Chip" }), renderValue: (selected) => (React.createElement(Box, { sx: { display: 'flex', flexWrap: 'wrap', gap: 0.5 } }, selected.map((value) => (React.createElement(Chip, { key: value, label: examIdMap[value] }))))), MenuProps: MenuProps }, Object.entries(examIdMap).map(([id, name]) => (React.createElement(MenuItem, { key: id, value: id, style: getStyles(id, examIDs, theme) },
                        React.createElement(Checkbox, { checked: examIDs.indexOf(id) > -1 }),
                        React.createElement(ListItemText, { primary: name })))))),
                React.createElement(FormControl, { sx: { width: '-webkit-fill-available' }, size: "small" },
                    React.createElement(InputLabel, { id: "demo-multiple-checkbox-label" }, "Students"),
                    React.createElement(Select, { labelId: "demo-multiple-checkbox-label", id: "demo-multiple-checkbox", multiple: true, value: studentIDs, onChange: handleStudentChange, input: React.createElement(OutlinedInput, { id: "select-multiple-chip", label: "Chip" }), renderValue: (selected) => (React.createElement(Box, { sx: { display: 'flex', flexWrap: 'wrap', gap: 0.5 } }, selected.map((value) => (React.createElement(Chip, { key: value, label: studentIdMap[value] }))))), MenuProps: MenuProps }, Object.entries(studentIdMap).map(([id, name]) => (React.createElement(MenuItem, { key: id, value: id, style: getStyles(id, studentIDs, theme) },
                        React.createElement(Checkbox, { checked: studentIDs.indexOf(id) > -1 }),
                        React.createElement(ListItemText, { primary: name }))))))),
            React.createElement(Button, { variant: "contained", onClick: () => dispatch(postStudentExamMapping({
                    name,
                    examIDs: examIDs.toString(),
                    studentIDs: studentIDs.toString(),
                })) }, "Submit")))));
};
export default StudentExamMappingModal;
