import { Button } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useStyle } from '../../styles/style';
import StudentExamMappingModal from '../Modal/StudentExamMappingModal';
import { getAllExams, getStudentTenantMapping } from '../../store/actions';
const StudentExamMapping = () => {
    var _a;
    const classes = useStyle();
    const dispatch = useDispatch();
    const [isModalOpen, setModalOpen] = useState(false);
    const loader = useSelector((state) => state.exam.loader);
    const studentExamMapping = useSelector((state) => state.exam.studentExamMapping);
    const exams = useSelector((state) => state.exam.exams);
    const studentTenantMapping = useSelector((state) => state.exam.studentTenantMapping);
    const examIdMap = Object.fromEntries(exams
        .filter((exam) => exam.publishStatus)
        .map((e) => [e.examID, e.examName]));
    const studentIdMap = Object.fromEntries(studentTenantMapping.map((student) => [
        student.studentId,
        `${student.firstName} ${student.middleName} ${student.lastName}    ${student.emailId}`,
    ]));
    useEffect(() => {
        dispatch(getAllExams());
        dispatch(getStudentTenantMapping());
    }, []);
    const columns = [
        { field: 'id' },
        { field: 'name', headerName: 'Name', width: 250 },
        { field: 'examIDs', headerName: 'Exams', width: 400 },
        { field: 'studentIDs', headerName: 'Student', width: 400 },
        { field: 'createdDate', headerName: ' Created On', width: 240 },
    ];
    const rows = (_a = studentExamMapping === null || studentExamMapping === void 0 ? void 0 : studentExamMapping.map((audit) => {
        const exam = audit.examIDs
            .split(',')
            .map((examID) => examIdMap[examID])
            .toString();
        const student = audit.studentIDs
            .split(',')
            .map((studentID) => studentIdMap[studentID])
            .toString();
        const e = {
            id: audit.studentExamMappingAuditID,
            name: audit.name,
            examIDs: exam,
            studentIDs: student,
            createdDate: new Date(audit.createdDate).toLocaleDateString(),
        };
        return e;
    })) !== null && _a !== void 0 ? _a : {};
    const handleButtonClick = () => {
        setModalOpen((prevValue) => !prevValue);
    };
    return (React.createElement("div", { style: { width: '-webkit-fill-available' } },
        React.createElement("div", { className: classes.studentExamMappingButtonDiv },
            React.createElement(Button, { onClick: handleButtonClick, variant: "contained", size: "large", style: { margin: '1rem' } }, "New Student Exam Mapping")),
        React.createElement("div", { style: { height: 600, width: '100%' } },
            React.createElement(DataGrid, { rows: rows, columns: columns, loading: loader.studentExamMapping, columnVisibilityModel: {
                    id: false,
                }, disableColumnMenu: true })),
        isModalOpen && (React.createElement(StudentExamMappingModal, { isModalOpen: isModalOpen, handleModalClose: handleButtonClick }))));
};
export default StudentExamMapping;
