import { axiosInstance, axiosInstanceBlob, handleApiResponse, handleApiResponseOther, } from './networkUtils';
const BASE_URL = `${process.env.REACT_APP_TEACHER_BACKEND_URL}/api`;
const STUDENT_URL_API = `${process.env.REACT_APP_STUDENT_BACKEND_URL}/api`;
const IP_URL = 'https://geolocation-db.com/json/';
const getAllExams = () => axiosInstance
    .get(`${BASE_URL}/Exam/tenant/exams`)
    .then((response) => handleApiResponse(response))
    .catch((error) => handleApiResponse(error));
const saveExamDetails = (payload) => axiosInstance
    .post(`${BASE_URL}/Exam`, payload)
    .then((response) => handleApiResponse(response))
    .catch((error) => handleApiResponse(error.response));
const saveExamSetDetails = (payload) => axiosInstance
    .post(`${BASE_URL}/Exam/${payload.examID}/Examset`, payload)
    .then((response) => handleApiResponse(response))
    .catch((error) => handleApiResponse(error.response));
const getAllExamSetDetails = (payload) => axiosInstance
    .get(`${BASE_URL}/Exam/${payload.examID}/Examset`)
    .then((response) => handleApiResponse(response))
    .catch((error) => handleApiResponse(error.response));
const saveQuestionDetails = (payload) => axiosInstance
    .post(`${BASE_URL}/Question`, payload)
    .then((response) => handleApiResponse(response))
    .catch((error) => handleApiResponse(error.response));
const getQuestionsByExamSet = (payload) => axiosInstance
    .get(`${BASE_URL}/Exam/Examset/${payload.examSetID}/questions?tenantId=${payload.tenantID}`)
    .then((response) => handleApiResponse(response))
    .catch((error) => handleApiResponse(error.response));
const postExamPublish = (payload) => axiosInstance
    .post(`${BASE_URL}/Exam/${payload.examID}/publish`)
    .then((response) => handleApiResponse(response))
    .catch((error) => handleApiResponse(error.response));
const postUpdateExam = (payload) => axiosInstance
    .post(`${BASE_URL}/Exam/${payload.examID}`, payload)
    .then((response) => handleApiResponse(response))
    .catch((error) => handleApiResponse(error.response));
const postUpdateExamSet = (payload) => axiosInstance
    .post(`${BASE_URL}/Exam/${payload.examID}/Examset/${payload.examSetID}`, payload.data)
    .then((response) => handleApiResponse(response))
    .catch((error) => handleApiResponse(error.response));
const postExamSearchRegistry = (payload) => axiosInstance
    .post(`${BASE_URL}/ExamSearch`, payload)
    .then((response) => handleApiResponse(response))
    .catch((error) => handleApiResponse(error.response));
const getExamReport = (payload) => axiosInstance
    .get(`${STUDENT_URL_API}/Tenant/${payload.tenantID}/exam-report`)
    .then((response) => handleApiResponse(response))
    .catch((error) => handleApiResponse(error.response));
const getBillingReport = (payload) => axiosInstance
    .get(`${STUDENT_URL_API}/Tenant/${payload.tenantID}/billing-report`)
    .then((response) => handleApiResponse(response))
    .catch((error) => handleApiResponse(error.response));
const getDashboardReport = (payload) => axiosInstance
    .get(`${STUDENT_URL_API}/Tenant/${payload.tenantID}/dashboard-report`)
    .then((response) => handleApiResponse(response))
    .catch((error) => handleApiResponse(error.response));
const getExamStudents = (payload) => axiosInstance
    .get(`${STUDENT_URL_API}/Tenant/${payload.examID}/students`)
    .then((response) => handleApiResponse(response))
    .catch((error) => handleApiResponse(error.response));
const postStudentExamResultAnalysis = (payload) => axiosInstance
    .post(`${STUDENT_URL_API}/Student/${payload.examID}/result-analysis`, payload.data)
    .then((response) => handleApiResponse(response))
    .catch((error) => handleApiResponse(error.response));
const postContactUsDetail = (payload) => axiosInstance
    .post(`${STUDENT_URL_API}/contactUs/save`, payload)
    .then((response) => handleApiResponse(response))
    .catch((error) => handleApiResponse(error.response));
const postUpdateQuestion = (payload) => axiosInstance
    .post(`${STUDENT_URL_API}/Exam/Question/${payload.questionID}`, payload.data)
    .then((response) => handleApiResponse(response))
    .catch((error) => handleApiResponse(error.response));
const postTeacherLogin = (payload) => axiosInstance
    .post(`${BASE_URL}/Login`, payload)
    .then((response) => handleApiResponse(response))
    .catch((error) => handleApiResponse(error.response));
const postTeacherSignUp = (payload) => axiosInstance
    .post(`${BASE_URL}/Tenant`, payload)
    .then((response) => handleApiResponse(response))
    .catch((error) => handleApiResponse(error.response));
const getIpGeoAddress = () => axiosInstance
    .get(`${IP_URL}`)
    .then((response) => handleApiResponseOther(response))
    .catch((error) => handleApiResponseOther(error.response));
const postPageAudit = (payload) => axiosInstance
    .post(`${STUDENT_URL_API}/Page/Track`, payload)
    .then((response) => handleApiResponse(response))
    .catch((error) => handleApiResponse(error.response));
const postTenantForgotPassword = (payload) => axiosInstance
    .post(`${BASE_URL}/Tenant/forgotPassword`, payload)
    .then((response) => handleApiResponse(response))
    .catch((error) => handleApiResponse(error.response));
const postTenantForgotPasswordVerify = (payload) => axiosInstance
    .post(`${BASE_URL}/Tenant/forgotPassword/verify`, payload)
    .then((response) => handleApiResponse(response))
    .catch((error) => handleApiResponse(error.response));
const postDownloadQuestionTemplate = () => axiosInstanceBlob
    .get(`${STUDENT_URL_API}/Question-Template/download`)
    .then((response) => handleApiResponseOther(response))
    .catch((error) => handleApiResponse(error.response));
const getStudentTenantMapping = (payload) => axiosInstance
    .get(`${STUDENT_URL_API}/tenant/student-mapping/${payload.tenantID}`)
    .then((response) => handleApiResponse(response))
    .catch((error) => handleApiResponse(error.response));
const getStudentExamMapping = (payload) => axiosInstance
    .get(`${STUDENT_URL_API}/tenant/student-exam-mapping/${payload.tenantID}`)
    .then((response) => handleApiResponse(response))
    .catch((error) => handleApiResponse(error.response));
const postStudentExamMapping = (payload) => axiosInstance
    .post(`${STUDENT_URL_API}/tenant/student-exam-mapping`, payload)
    .then((response) => handleApiResponse(response))
    .catch((error) => handleApiResponse(error.response));
const postStudentStatus = (payload) => axiosInstance
    .post(`${STUDENT_URL_API}/tenant/student-status`, payload)
    .then((response) => handleApiResponse(response))
    .catch((error) => handleApiResponse(error.response));
const getTenantCode = (payload) => axiosInstance
    .get(`${STUDENT_URL_API}/tenant/getCode/${payload.tenantID}`)
    .then((response) => handleApiResponse(response))
    .catch((error) => handleApiResponse(error.response));
export const service = {
    getAllExams,
    saveExamDetails,
    saveExamSetDetails,
    getAllExamSetDetails,
    saveQuestionDetails,
    getQuestionsByExamSet,
    postExamPublish,
    postUpdateExam,
    postUpdateExamSet,
    postExamSearchRegistry,
    getExamReport,
    getBillingReport,
    getDashboardReport,
    getExamStudents,
    postStudentExamResultAnalysis,
    postUpdateQuestion,
    postContactUsDetail,
    postTeacherLogin,
    postTeacherSignUp,
    getIpGeoAddress,
    postPageAudit,
    postTenantForgotPassword,
    postTenantForgotPasswordVerify,
    postDownloadQuestionTemplate,
    getStudentTenantMapping,
    postStudentStatus,
    getStudentExamMapping,
    postStudentExamMapping,
    getTenantCode,
};
