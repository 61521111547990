export const USER_SIGN_OUT = 'USER_SIGN_OUT';
export const RESET_PASSWORD_MODAL_OPEN = 'RESET_PASSWORD_MODAL_OPEN';
export const RESET_PASSWORD_MODAL_CLOSE = 'RESET_PASSWORD_MODAL_CLOSE';
export const POST_TEACHER_LOGIN_SUCCESS = 'POST_TEACHER_LOGIN_SUCCESS';
export const LOGIN_MODAL_OPEN = 'LOGIN_MODAL_OPEN';
export const POST_TEACHER_SIGN_UP = 'POST_TEACHER_SIGN_UP';
export const POST_TEACHER_SIGN_UP_SUCCESS = 'POST_TEACHER_SIGN_UP_SUCCESS';
export const POST_TEACHER_SIGN_UP_FAILURE = 'POST_TEACHER_SIGN_UP_FAILURE';
export const LOGIN_MODAL_CLOSE = 'LOGIN_MODAL_CLOSE';
export const FORGOT_PASSWORD_MODAL_OPEN = 'FORGOT_PASSWORD_MODAL_OPEN';
export const POST_TEACHER_LOGIN = 'POST_TEACHER_LOGIN';
export const SIGNUP_MODAL_OPEN = 'SIGNUP_MODAL_OPEN';
export const SIGNUP_MODAL_CLOSE = 'SIGNUP_MODAL_CLOSE';
export const FORGOT_PASSWORD_MODAL_CLOSE = 'FORGOT_PASSWORD_MODAL_CLOSE';
export const POST_TENANT_FORGOT_PASSWORD = 'POST_TENANT_FORGOT_PASSWORD';
export const POST_TENANT_FORGOT_PASSWORD_SUCCESS = 'POST_TENANT_FORGOT_PASSWORD_SUCCESS';
export const POST_TENANT_FORGOT_PASSWORD_FAILURE = 'POST_TENANT_FORGOT_PASSWORD_FAILURE';
export const POST_TENANT_FORGOT_PASSWORD_VERIFY = 'POST_TENANT_FORGOT_PASSWORD_VERIFY';
export const POST_FORGOT_PASSWORD_VERIFY_SUCCESS = 'POST_FORGOT_PASSWORD_VERIFY_SUCCESS';
export const GET_TENANT_CODE = 'GET_TENANT_CODE';
export const GET_TENANT_CODE_SUCCESS = 'GET_TENANT_CODE_SUCCESS';
