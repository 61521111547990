import { Typography } from '@mui/material';
import JoditEditor from 'jodit-react';
import React, { useMemo, useRef } from 'react';
import { useStyle } from '../../styles/style';
const EditorCK = ({ placeholder, onChange, errors, disabled, value }) => {
    const classes = useStyle();
    const editor = useRef(null);
    console.log('Hii');
    const buttons = [
        'undo',
        'redo',
        '|',
        'bold',
        'strikethrough',
        'underline',
        'italic',
        '|',
        'superscript',
        'subscript',
        '|',
        'align',
        '|',
        'ul',
        'ol',
        'outdent',
        'indent',
        '|',
        'font',
        'fontsize',
        'brush',
        'paragraph',
        '|',
        'image',
        'link',
        'table',
        '|',
        'hr',
        'eraser',
        'copyformat',
        '|',
        'fullsize',
        'selectall',
        'print',
        '|',
        'source',
    ];
    const editorConfig = {
        disabled,
        toolbar: true,
        language: 'en',
        buttons,
        statusbar: false,
        placeholder,
    };
    return useMemo(() => (React.createElement(React.Fragment, null,
        React.createElement(JoditEditor, { ref: editor, value: value, onBlur: (data) => onChange(data), config: editorConfig }),
        errors && (React.createElement(Typography, { variant: "caption", className: classes.error }, errors.message)))), [placeholder, value, errors, disabled]);
};
export default EditorCK;
