var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { zodResolver } from '@hookform/resolvers/zod';
import AddIcon from '@mui/icons-material/Add';
import CancelSharpIcon from '@mui/icons-material/CancelSharp';
import { Button, IconButton, Radio, TextField, Typography, RadioGroup, FormControlLabel, } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { z } from 'zod';
import * as Actions from '../../store/actions';
import { useStyle } from '../../styles/style';
import EditorCK from '../BaseComponents/EditorCK';
import QuestionPreviewModal from './QuestionPreviewModal';
const getChoiceList = (fields, classes, remove, control, errors, dispatch, disabled) => {
    const selectedChoice = fields.findIndex((f) => f.isCorrect);
    return (React.createElement(Controller, { rules: { required: true }, control: control, name: "correctChoice", render: ({ field }) => (React.createElement(RadioGroup, Object.assign({}, field, { value: field.value || selectedChoice }), fields.map((f, index) => (React.createElement(React.Fragment, null,
            React.createElement(Typography, null, `Choice ${index + 1}`),
            React.createElement("div", { className: classes.choiceDiv, key: f.id },
                React.createElement(FormControlLabel, { value: index, control: React.createElement(Radio, null), label: "", disabled: disabled }),
                React.createElement(Controller, { control: control, name: `choices.${index}.choice`, key: f.id, render: ({ field: { onChange, value } }) => {
                        var _a, _b;
                        return (React.createElement(EditorCK, { onChange: onChange, errors: (_b = (_a = errors.choices) === null || _a === void 0 ? void 0 : _a[index]) === null || _b === void 0 ? void 0 : _b.choice, value: value, disabled: disabled, placeholder: "" }));
                    } }),
                React.createElement(IconButton, { onClick: () => {
                        if (fields.length > 2)
                            remove(index);
                        else
                            dispatch(Actions.setSnackbar({
                                snackbarOpen: true,
                                snackbarType: 'warning',
                                snackbarMsg: 'Minimum Two Choices are required',
                            }));
                    }, className: classes.crossIcon, disabled: disabled },
                    React.createElement(CancelSharpIcon, null)))))))) }));
};
const ExamQuestionForm = (props) => {
    var _a, _b;
    const { defaultValues, examSet, access, index } = props;
    const [disabled, setDisabled] = useState(false);
    const [isUpdate, setIsUpdate] = useState(false);
    const [previewModal, setPreviewModal] = useState(false);
    const classes = useStyle();
    const dispatch = useDispatch();
    const user = useSelector((state) => state.auth.user);
    const questionFormValidation = z.object({
        questionDescription: z
            .string()
            .nonempty('Question description is Required'),
        correctMark: z.number().min(1).max(1000),
        negativeMark: z.number().max(1000),
        correctChoice: z.string(),
        questionHint: z.string().nonempty(),
        choices: z
            .object({
            isCorrect: z.boolean(),
            choice: z.string().nonempty(' Choice is Required'),
        })
            .array()
            .min(2),
    });
    const questionForm = useForm({
        defaultValues,
        resolver: zodResolver(questionFormValidation),
        mode: 'onChange',
        values: defaultValues,
    });
    const { register, handleSubmit, formState: { errors }, control, getValues, } = questionForm;
    const { fields, append, remove } = useFieldArray({
        name: 'choices',
        control,
        rules: { minLength: 2 },
    });
    useEffect(() => {
        var _a;
        if ((_a = defaultValues.questionDescription) === null || _a === void 0 ? void 0 : _a.length)
            setIsUpdate(true);
        if (access)
            setDisabled(true);
    }, [defaultValues, access]);
    useEffect(() => {
        console.log(getValues());
        if (errors && errors.correctChoice) {
            dispatch(Actions.setSnackbar({
                snackbarOpen: true,
                snackbarType: 'warning',
                snackbarMsg: 'Please select one choice to submit.',
            }));
        }
    }, [errors]);
    // useEffect(() => {
    //   const selectedChoice = fields.findIndex((f) => f.isCorrect);
    //   console.log(selectedChoice);
    //   if (selectedChoice) setValue('correctChoice', selectedChoice.toString());
    // }, [defaultValues, fields]);
    const questionPreviewHandler = () => {
        setPreviewModal(true);
    };
    const handleQuestionSubmit = (data) => {
        const commonPayload = Object.assign(Object.assign({ activeStatus: true }, (!isUpdate && { createdBy: user.emailId })), { modifiedBy: user.emailId });
        const { correctChoice } = data, newData = __rest(data, ["correctChoice"]);
        const payload = Object.assign(Object.assign(Object.assign({}, newData), commonPayload), { examSetID: examSet.examSetID, choices: newData.choices.map((choice, index) => (Object.assign(Object.assign(Object.assign({}, choice), commonPayload), { isCorrect: Number(correctChoice) === index }))) });
        if (isUpdate)
            dispatch(Actions.postUpdateQuestion({
                data: payload,
                examSetID: examSet.examSetID,
                questionIndex: index - 1,
            }));
        else {
            dispatch(Actions.saveQuestionDetails(payload));
            setIsUpdate(true);
        }
    };
    // console.log(questionForm.getValues());
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: classes.questionForm },
            React.createElement(Typography, { variant: "body2" }, "Question"),
            React.createElement(Controller, { control: control, name: "questionDescription", render: ({ field: { onChange, value } }) => (React.createElement(EditorCK, { onChange: onChange, errors: errors.questionDescription, value: value, disabled: disabled, placeholder: "" })) }),
            React.createElement("div", { className: classes.questionFormDiv },
                React.createElement(TextField, Object.assign({ label: "Marks for correct answer", variant: "outlined", size: "small", type: "tel" }, register('correctMark', { valueAsNumber: true }), { error: !!errors.correctMark, helperText: (_a = errors.correctMark) === null || _a === void 0 ? void 0 : _a.message, disabled: disabled })),
                React.createElement(TextField, Object.assign({ label: "Marks for wrong answer", variant: "outlined", size: "small", type: "tel" }, register('negativeMark', { valueAsNumber: true }), { error: !!errors.negativeMark, helperText: (_b = errors.negativeMark) === null || _b === void 0 ? void 0 : _b.message, disabled: disabled }))),
            React.createElement(Typography, { variant: "h3" }, "Choices"),
            getChoiceList(fields, classes, remove, control, errors, dispatch, disabled),
            React.createElement("div", null,
                React.createElement(IconButton, { onClick: () => {
                        append({ isCorrect: false, choice: '' });
                    }, className: classes.addButtonDiv, disabled: disabled },
                    React.createElement(AddIcon, null))),
            React.createElement(Typography, { variant: "body2" }, "Answer Hint (Explanation)"),
            React.createElement(Controller, { control: control, name: "questionHint", render: ({ field: { onChange, value } }) => (React.createElement(EditorCK, { onChange: onChange, errors: errors.questionHint, value: value, disabled: disabled, placeholder: "" })) }),
            React.createElement("div", { className: classes.examFormButton },
                React.createElement(Button, { variant: "contained", disableElevation: true, color: "primary", onClick: questionPreviewHandler }, "Preview"),
                React.createElement(Button, { variant: "contained", disableElevation: true, color: "primary", type: "submit", onClick: handleSubmit(handleQuestionSubmit), disabled: disabled }, isUpdate ? 'Update' : 'Submit'))),
        React.createElement(QuestionPreviewModal, { open: previewModal, handleClose: () => setPreviewModal(false), questionDescription: questionForm.getValues().questionDescription, choices: questionForm.getValues().choices })));
};
export default ExamQuestionForm;
