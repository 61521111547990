import { IconButton, Typography } from '@material-ui/core';
import { AccountBalanceWalletOutlined, AssessmentOutlined, FormatListBulletedOutlined, Group, PostAdd, } from '@mui/icons-material';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import { useTour } from '@reactour/tour';
import { clsx } from 'clsx';
import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { useStyle } from '../styles/style';
const SidePanel = () => {
    const classes = useStyle();
    const { setIsOpen } = useTour();
    const [isExpanded, setIsExpanded] = useState(true);
    const expandHandler = () => {
        const sidePanel = document.getElementById('sidePanel');
        const formDiv = document.getElementById('examForm');
        if (isExpanded) {
            if (sidePanel)
                sidePanel.style.minWidth = '4rem';
            if (formDiv) {
                formDiv.style.minWidth = '53rem';
                formDiv.style.maxWidth = '53rem';
            }
        }
        else {
            if (sidePanel)
                sidePanel.style.minWidth = '13rem';
            if (formDiv) {
                formDiv.style.minWidth = '45rem';
                formDiv.style.maxWidth = '45rem';
            }
        }
        setIsExpanded((prevState) => !prevState);
    };
    return (React.createElement("div", { className: classes.sidePanelBody, id: "sidePanel" },
        isExpanded ? (React.createElement(IconButton, { className: clsx(classes.backButton, classes.rotate), onClick: expandHandler },
            React.createElement(ArrowRightAltIcon, null))) : (React.createElement(IconButton, { className: classes.backButton, onClick: expandHandler },
            React.createElement(ArrowRightAltIcon, null))),
        React.createElement("ul", { className: classes.sidePanelList, id: "sidePanelList" },
            isExpanded && React.createElement(Typography, null, "Examination"),
            React.createElement(NavLink, { to: "/exams", className: classes.navLink, id: "exams", onClick: () => setIsOpen(false) },
                React.createElement("li", null,
                    React.createElement(FormatListBulletedOutlined, { className: classes.icon }),
                    isExpanded && (React.createElement(Typography, { className: classes.title }, "Exams")))),
            React.createElement(NavLink, { to: "/exam-report", className: classes.navLink },
                React.createElement("li", null,
                    React.createElement(AssessmentOutlined, { className: classes.icon }),
                    isExpanded && (React.createElement(Typography, { className: classes.title }, "Exam Report")))),
            React.createElement(NavLink, { to: "/billing-report", className: classes.navLink },
                React.createElement("li", null,
                    React.createElement(AccountBalanceWalletOutlined, { className: classes.icon }),
                    isExpanded && (React.createElement(Typography, { className: classes.title }, "Exam Billing")))),
            React.createElement(NavLink, { to: "/students", className: classes.navLink },
                React.createElement("li", null,
                    React.createElement(Group, { className: classes.icon }),
                    isExpanded && (React.createElement(Typography, { className: classes.title }, "My Students")))),
            React.createElement(NavLink, { to: "/map-student-exam", className: classes.navLink },
                React.createElement("li", null,
                    React.createElement(PostAdd, { className: classes.icon }),
                    isExpanded && (React.createElement(Typography, { className: classes.title }, "Map Exam")))))));
};
export default SidePanel;
