import React from 'react';
import { useDispatch } from 'react-redux';
import { Navigate, Route, RouterProvider, createBrowserRouter, createRoutesFromElements, } from 'react-router-dom';
import AddExam from '../components/ExamForm/AddExam';
import ModifyViewForm from '../components/ExamForm/ModifyViewForm';
import ExamSet from '../components/ExamSet';
import ExamSetDescription from '../components/ExamSet/ExamSetDescription';
import ExamSetQuestion from '../components/ExamSet/ExamSetQuestion';
import Exams from '../components/ExamTable';
import LandingComponent from '../components/Landing';
import MainComponent from '../components/MainComponent';
import ContactUs from '../components/Pages/ContactUs';
import PrivacyPolicy from '../components/Pages/PrivacyPolicy';
import RefundPolicy from '../components/Pages/ReturnPolicy';
import TermsAndCondition from '../components/Pages/TermsAndCondition';
import BillingReport from '../components/report/BillingReport';
import ExamReport from '../components/report/ExamReport';
import ResultAnalysis from '../components/report/ResultAnalysis';
import StudentReport from '../components/report/StudentReport';
import * as Actions from '../store/actions';
import ForgotPassword from '../components/Pages/ForgotPassword';
import StudentMapping from '../components/report/StudentMapping';
import StudentExamMapping from '../components/Pages/StudentExamMapping';
const RouteProvider = () => {
    const dispatch = useDispatch();
    const router = createBrowserRouter(createRoutesFromElements(React.createElement(Route, { path: "/", element: React.createElement(MainComponent, null), loader: () => dispatch(Actions.getDashboardReport()), errorElement: React.createElement(Navigate, { to: "/" }) },
        React.createElement(Route, { path: "/", element: React.createElement(LandingComponent, null), errorElement: React.createElement(Navigate, { to: "/" }) }),
        React.createElement(Route, { path: "exams", loader: () => dispatch(Actions.getAllExams()), element: React.createElement(Exams, null) },
            React.createElement(Route, { path: "add", element: React.createElement(AddExam, null) }),
            React.createElement(Route, { path: ":examID/view", element: React.createElement(ModifyViewForm, { view: true }), loader: () => dispatch(Actions.getAllExams()) }),
            React.createElement(Route, { path: ":examID/edit", element: React.createElement(ModifyViewForm, { view: false }) }),
            React.createElement(Route, { path: ":examID/sets", element: React.createElement(ExamSetDescription, null), loader: (param) => dispatch(Actions.getAllExamSetDetails(param)) })),
        React.createElement(Route, { path: ":examID/sets", element: React.createElement(ExamSet, null) },
            React.createElement(Route, { path: ":setID", element: React.createElement(ExamSetQuestion, null), loader: (param) => dispatch(Actions.getQuestionPageLoad(param)) })),
        React.createElement(Route, { path: "forgotPassword", element: React.createElement(ForgotPassword, null), errorElement: React.createElement(Navigate, { to: "/" }) }),
        React.createElement(Route, { path: "exam-report", element: React.createElement(ExamReport, null), loader: () => dispatch(Actions.getExamReport()) }),
        React.createElement(Route, { path: "billing-report", element: React.createElement(BillingReport, null), loader: () => dispatch(Actions.getBillingReport()) }),
        React.createElement(Route, { path: "students", element: React.createElement(StudentMapping, null), loader: () => dispatch(Actions.getStudentTenantMapping()) }),
        React.createElement(Route, { path: "map-student-exam", element: React.createElement(StudentExamMapping, null), loader: () => dispatch(Actions.getStudentExamMapping()) }),
        React.createElement(Route, { path: "Term-Condition.html", element: React.createElement(TermsAndCondition, null), errorElement: React.createElement(Navigate, { to: "/" }) }),
        React.createElement(Route, { path: "Term-Condition", element: React.createElement(TermsAndCondition, null), errorElement: React.createElement(Navigate, { to: "/" }) }),
        React.createElement(Route, { path: "Privacy-policy.html", element: React.createElement(PrivacyPolicy, null), errorElement: React.createElement(Navigate, { to: "/" }) }),
        React.createElement(Route, { path: "Privacy-policy", element: React.createElement(PrivacyPolicy, null), errorElement: React.createElement(Navigate, { to: "/" }) }),
        React.createElement(Route, { path: "contactUs", element: React.createElement(ContactUs, null), errorElement: React.createElement(Navigate, { to: "/" }) }),
        React.createElement(Route, { path: "refund-policy", element: React.createElement(RefundPolicy, null) }),
        React.createElement(Route, { path: "refund-policy.html", element: React.createElement(RefundPolicy, null) }),
        React.createElement(Route, { path: ":examID/students", element: React.createElement(StudentReport, null), loader: (param) => dispatch(Actions.getStudentsInExam(param)) }),
        React.createElement(Route, { path: ":examID/:studentID/result-analysis", element: React.createElement(ResultAnalysis, null), loader: (param) => dispatch(Actions.getStudentExamResultAnalysis(param)) }),
        React.createElement(Route, { path: "*", element: React.createElement(Navigate, { to: "/" }) }))));
    return React.createElement(RouterProvider, { router: router });
};
export default RouteProvider;
